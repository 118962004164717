export type Currency = "DKK" | "SEK" | "NOK" | "USD" | "GBP";

const formatters: { [T in Currency]: Intl.NumberFormat } = {
  DKK: new Intl.NumberFormat("da-DK", {
    style: "currency",
    currency: "DKK"
  }),
  SEK: new Intl.NumberFormat("sv-SE", {
    style: "currency",
    currency: "SEK"
  }),
  NOK: new Intl.NumberFormat("nb-NO", {
    style: "currency",
    currency: "NOK"
  }),
  GBP: new Intl.NumberFormat("ga-IE", {
    style: "currency",
    currency: "GBP"
  }),
  USD: new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD"
  })
};

export const formatCurrency = (value: number, currency: Currency = "DKK") => {
  return formatters[currency].format(value / 100);
};
