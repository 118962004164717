import React from "react";
import { useField } from "formik";
import { BaseField, BaseFieldProps } from "../Base";
import { CleaveOptions } from "cleave.js/options";
import Cleave from "cleave.js/react";

export type InputProps = { cleave?: CleaveOptions } & Omit<
  BaseFieldProps,
  "children"
> &
  React.HTMLProps<HTMLInputElement>;

export const Input: React.FC<InputProps> = ({
  name,
  cleave,
  type = "text",
  ...props
}) => {
  const [field, meta] = useField(name);
  const error = meta.error && meta.touched ? meta.error : undefined;

  return (
    <BaseField name={name} error={error} {...props}>
      {cleave ? (
        <Cleave {...field} options={cleave} />
      ) : (
        <input type={type} {...field} />
      )}
    </BaseField>
  );
};
