import React from "react";
import { BaseField, BaseFieldProps } from "../Base";
import * as S from './styles';

export type InputReadOnlyProps = Omit<
  BaseFieldProps,
  "children"
> &
  React.HTMLProps<HTMLInputElement>;

export const InputReadOnly: React.FC<InputReadOnlyProps> = ({
  type = "text",
  ...props
}) => {
  const { value } = props;

  return (
    <BaseField {...props}>
      <div className="input">
        <S.Value>{value}</S.Value>
      </div>
    </BaseField>
  );
};
