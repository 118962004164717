import { Wizard } from "components/Wizard";
import { useGetApplication } from "hooks/useApplication";
import React from "react";
import { Redirect, Route, RouteComponentProps, Switch } from "react-router-dom";

import { ContactsPage } from "./pages/Contacts";
import { InformationPage } from "./pages/Information";
import { SubmitPage } from "./pages/Submit";
import { AwaitingApprovalPage } from "./pages/AwaitingApproval";

export type FlowPageProps = RouteComponentProps;

export const FlowPage: React.FC<FlowPageProps> = ({ match }) => {
  const { url, path } = match;
  const { loading, error, data } = useGetApplication();

  if (error) return <div>error</div>;
  if (loading) return <div>loading...</div>;

  const { application } = data;
  const state = application?.state.toLowerCase();

  switch (state) {
    case "onboarding":
      return (
        <Wizard steps={3}>
          <Switch>
            <Route path={`${path}/1`} component={InformationPage} />
            <Route path={`${path}/2`} component={ContactsPage} />
            {/* <Route path={`${path}/3`} component={AssetsPage} /> */}
            <Route path={`${path}/3`} component={SubmitPage} />
            <Redirect to={`${url}/1`} />
          </Switch>
        </Wizard>
      );

    case "pendingapproval":
      return <AwaitingApprovalPage />;

    default:
      return <Redirect to="/unauthorized" />;
  }
};
