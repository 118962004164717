import {
  PageLayout,
  PageLayoutNavigationList,
  PageLayoutNavigationItem,
  PageLayoutNavigationLink
} from "@e-boks/react-dls";

import React from "react";
import * as S from "./styles";
import { Trans } from "@lingui/macro";

export const MainLayout: React.FC = ({ children }) => {
  return (
    <PageLayout
      brand="onboarding"
      navigation={
        <PageLayoutNavigationList>
          <PageLayoutNavigationItem>
            <PageLayoutNavigationLink to="/logout">
              <S.LogoutIcon />
              <Trans>navigation.logout</Trans>
            </PageLayoutNavigationLink>
          </PageLayoutNavigationItem>
        </PageLayoutNavigationList>
      }
    >
      {children}
    </PageLayout>
  );
};
