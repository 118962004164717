import React from "react";
import * as S from "./styles";

export type FieldsetProps = {
  name: string;
  title: React.ReactNode;
  description?: React.ReactNode;
};

export const Fieldset: React.FC<FieldsetProps> = ({
  name,
  title,
  description,
  children
}) => {
  return (
    <S.Base>
      <S.Meta>
        <div>{/* icon */}</div>
        <S.Title id={`${name}-title`}>{title}</S.Title>
        <S.Description id={`${name}-description`}>{description}</S.Description>
      </S.Meta>
      <S.Content>{children}</S.Content>
    </S.Base>
  );
};
