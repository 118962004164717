import React from "react";

import * as S from "./styles";
import { Link } from "react-router-dom";
import { filterProps } from "@e-boks/react-utils";

export type CommonButtonProps = {
  to?: string;
  type?: "button" | "submit" | "reset";
  color?: "red" | "blue" | "white";
  rounded?: boolean;
  loading?: boolean;
};

export type BaseButtonProps = {
  nude?: false;
  outline?: false;
} & CommonButtonProps;

export type NudeButtonProps = {
  nude: true;
  outline?: false;
} & CommonButtonProps;

export type OutlineButtonProps = {
  nude?: false;
  outline: true;
} & CommonButtonProps;

export type ButtonProps =
  | BaseButtonProps
  | NudeButtonProps
  | OutlineButtonProps;

export const Button: React.FC<ButtonProps &
  React.HTMLProps<HTMLButtonElement>> = ({
  to,
  type = "button",
  color = "red",
  rounded = false,
  loading = false,
  nude = false,
  outline = false,
  children,
  ...props
}) => {
  return (
    <S.Base
      to={to}
      type={type}
      nude={nude}
      color={color}
      outline={outline}
      rounded={rounded}
      loading={loading}
      as={to ? S.LinkFiltered : null}
      {...(props as any)}
    >
      <S.Spinner loading={loading} />
      <S.Text loading={loading}>{children}</S.Text>
    </S.Base>
  );
};
