import React from "react";

import { Header } from "./components/Header";
import { Content } from "./components/Content";
import { Navigation } from "./components/Navigation";
export * from "./components/NavigationList";
export * from "./components/NavigationLink";

export type PageLayoutProps = {
  brand?: "sender" | "express" | "onboarding";
  header?: React.ReactNode;
  children?: React.ReactNode;
  navigation?: React.ReactNode;
  preContent?: React.ReactNode;
  postContent?: React.ReactNode;
  fullWidth?: boolean;
};

export const PageLayout: React.FC<PageLayoutProps> = ({
  brand,
  header,
  fullWidth = false,
  navigation,
  preContent,
  children,
  postContent,
  ...props
}) => {
  const [isMenuActive, setIsMenuActive] = React.useState(false);

  const handleMenuToggleClick = () => {
    setIsMenuActive(isMenuActive => !isMenuActive);
  };

  return (
    <div {...props}>
      <Header
        brand={brand}
        isMenuActive={isMenuActive}
        onMenuToggleClick={handleMenuToggleClick}
      >
        {header}
      </Header>

      <Navigation
        isMenuActive={isMenuActive}
        onMenuToggleClick={handleMenuToggleClick}
      >
        {navigation}
      </Navigation>

      <main>
        {preContent}
        <Content fullWidth={fullWidth}>{children}</Content>
        {postContent}
      </main>
    </div>
  );
};
