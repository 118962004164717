import React from "react";

export function createContext<A>() {
  const context = React.createContext<A | undefined>(undefined);

  function useContext() {
    const c = React.useContext(context);

    if (!c) throw new Error("useCtx must be inside a Provider with a value");

    return c;
  }

  return [context, useContext] as const;
}
