import { CognitoAuth } from "amazon-cognito-auth-js";
import { EventEmitter } from "events";
import { User } from "./User";

export type CognitoConfig = {
  userPoolId: string;
  clientId: string;
  domain: string;
  redirectUri: string;
  logoutUri: string;
  scope: string[];
};

export class Cognito {
  private auth: CognitoAuth;

  public events = new EventEmitter();

  constructor(config: CognitoConfig) {
    this.auth = new CognitoAuth({
      UserPoolId: config.userPoolId,
      ClientId: config.clientId,
      AppWebDomain: config.domain,
      RedirectUriSignIn: config.redirectUri,
      RedirectUriSignOut: config.logoutUri,
      TokenScopesArray: config.scope
    });

    this.auth.useCodeGrantFlow();

    const self = this;
    this.auth.userhandler = {
      onSuccess: res => {
        self.events.emit("success", res);
      },
      onFailure: err => {
        self.events.emit("error", err);
      }
    };
  }

  public get user() {
    return new User(this.auth.getSignInUserSession().getIdToken());
  }

  public get isLoggedIn() {
    return this.auth.isUserSignedIn();
  }

  public get accessToken() {
    return this.auth
      .getSignInUserSession()
      .getAccessToken()
      .getJwtToken();
  }

  public get idToken() {
    return this.auth
      .getSignInUserSession()
      .getIdToken()
      .getJwtToken();
  }

  public login() {
    this.auth.getSession();
  }

  public logout() {
    this.auth.signOut();
  }

  public callback() {
    return new Promise((resolve, reject) => {
      this.auth.parseCognitoWebResponse(window.location.href);

      this.events.once("success", (res: any) => {
        resolve(res);
      });

      this.events.once("failure", (err: any) => {
        reject(err);
      });
    });
  }
}
