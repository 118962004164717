import { Button, Input, InputReadOnly, Textarea } from "@e-boks/react-dls";
import { t, Trans } from "@lingui/macro";
import { useWizard } from "components/Wizard";
import { Form, Formik, FormikConfig } from "formik";
import {
  GET_APPLICATION,
  useGetApplication,
  useUpdateApplication
} from "hooks/useApplication";
import React from "react";
import { useI18n } from "@e-boks/react-i18n";
import * as yup from "yup";

import { Fieldset } from "../../components/Fieldset";
import { Layout } from "../../components/Layout";
import * as S from "./styles";
import { inputFormat } from "../../formatting";

export type InformationPageValues = {
  name: string;
  information: string;
  organisationId: string;
  address: {
    city: string;
    street: string;
    zipCode: string;
    country: string;
  };
};

export const InformationPage: React.FC = () => {
  const i18n = useI18n();
  const wizard = useWizard();
  const { loading, error, data } = useGetApplication();
  const [updateApplication] = useUpdateApplication();

  if (error) return <div>error</div>;
  if (loading) return <div>loading...</div>;

  const { application } = data;

  const form: FormikConfig<InformationPageValues> = {
    initialValues: {
      name: application.name || "",
      information: application.information || "",
      organisationId: application.organisationId || "",
      address: {
        city: application.address.city || "",
        street: application.address.street || "",
        zipCode: application.address.zipCode || "",
        country: i18n.language.split("-").pop()!
      }
    },

    validationSchema: yup.object().shape({
      name: yup.string().required(i18n._(t`application.name.error.required`)),
      information: yup
        .string()
        .max(255, i18n._(t`application.information.error.max`))
        .required(i18n._(t`application.information.error.required`)),
        organisationId: yup
        .string()
        .min(
          inputFormat(i18n.language).organisationId.validation.min,
          i18n._(t`application.organisationId.error.min`)
        )
        .required(i18n._(t`application.organisationId.error.required`)),
      address: yup.object().shape({
        city: yup
          .string()
          .required(i18n._(t`application.address.city.error.required`)),
        street: yup
          .string()
          .required(i18n._(t`application.address.street.error.required`)),
        zipCode: yup
          .string()
          .min(
            inputFormat(i18n.language).postalCode.validation.min,
            i18n._(t`application.address.zipCode.error.min`)
          )
          .required(i18n._(t`application.address.zipCode.error.required`))
      })
    }),

    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        await updateApplication({
          variables: { input: { ...application, ...values } },

          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: GET_APPLICATION
            }
          ]
        });
      } catch {
        setSubmitting(false);
      }

      wizard.next();
    }
  };

  return (
    <Formik {...form}>
      {({ isSubmitting }) => (
        <Form>
          <Layout>
            {{
              heading: <Trans>application.information.heading</Trans>,
              main: (
                <>
                  <Fieldset
                    name="organisationId"
                    title={<Trans>application.organisationId.title</Trans>}
/*                     description={
                      <Trans>application.organisationId.description</Trans>
                    } */
                  >
                    <div style={{display: 'none'}}>
                      <Input
                        block
                        cleave={
                          inputFormat(i18n.language).organisationId.cleave
                        }
                        name="organisationId"
                        placeholder={i18n._(
                          t`application.organisationId.placeholder`
                        )}
                        
                      />
                    </div>
                    <InputReadOnly block name="organisationIdReadOnly" value={application.organisationId} />
                  </Fieldset>

                  <Fieldset
                    name="name"
                    title={<Trans>application.name.title</Trans>}
                    description={<Trans>application.name.description</Trans>}
                  >
                    <Input
                      block
                      name="name"
                      placeholder={i18n._(t`application.name.placeholder`)}
                    />
                  </Fieldset>

                  <Fieldset
                    name="address"
                    title={<Trans>application.address.title</Trans>}
                    description={<Trans>application.address.description</Trans>}
                  >
                    <S.AddressInputGroup>
                      <Input
                        block
                        name="address.street"
                        placeholder={i18n._(
                          t`application.address.street.placeholder`
                        )}
                      />
                      <Input
                        block
                        cleave={inputFormat(i18n.language).postalCode.cleave}
                        name="address.zipCode"
                        placeholder={i18n._(
                          t`application.address.zipCode.placeholder`
                        )}
                      />
                      <Input
                        block
                        name="address.city"
                        placeholder={i18n._(
                          t`application.address.city.placeholder`
                        )}
                      />
                    </S.AddressInputGroup>
                  </Fieldset>

                  <Fieldset
                    name="information"
                    title={<Trans>application.information.title</Trans>}
                    description={
                      <Trans>application.information.description</Trans>
                    }
                  >
                    <Textarea
                      block
                      name="information"
                      minRows={8}
                      placeholder={i18n._(
                        t`application.information.placeholder`
                      )}
                    />
                  </Fieldset>
                </>
              ),
              buttons: (
                <>
                  <Button
                    rounded
                    type="submit"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    <Trans>application.details.next</Trans>
                  </Button>
                </>
              )
            }}
          </Layout>
        </Form>
      )}
    </Formik>
  );
};
