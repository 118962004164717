import React from "react";
import * as S from "./styles";

export type BaseFieldProps<T = HTMLInputElement> = {
  name: string;
  label?: string;
  error?: string;
  block?: boolean;
  className?: string;
  prefix?: React.ReactElement;
  postfix?: React.ReactElement;
  children: React.ReactElement;
} & React.HTMLProps<T>;

export const BaseField: React.FC<BaseFieldProps> = ({
  name,
  children,
  label = null,
  error = null,
  block = false,
  prefix = null,
  postfix = null,
  className = "",
  ...props
}) => {
  return (
    <S.Container block={block} className={className}>
      {label && <S.Label htmlFor={name}>{label}</S.Label>}
      <S.Field>
        {prefix}

        {React.cloneElement(children, {
          ...props,
          id: name,
          "aria-invalid": Boolean(error),
          "aria-errormessage": `${name}-errormessage`,
          "aria-describedby": `${name}-title ${name}-description`
        })}

        {postfix}
      </S.Field>
      {error && <S.Error id={`${name}-errormessage`}>{error}</S.Error>}
    </S.Container>
  );
};
