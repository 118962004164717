import React from "react";

import * as S from "./styles";

export type BaseLayoutNavigationProps = {
  isMenuActive?: boolean;
  onMenuToggleClick?: () => void;
};

export const Navigation: React.FC<BaseLayoutNavigationProps> = ({
  children,
  isMenuActive = false,
  onMenuToggleClick = () => {}
}) => {
  const stopPropagation = (event: React.MouseEvent) => {
    event.stopPropagation();
  };

  return (
    <S.Base isMenuActive={isMenuActive} onClick={onMenuToggleClick}>
      <S.Inner isMenuActive={isMenuActive} onClick={stopPropagation}>
        {children}
      </S.Inner>
      <S.GlobalStyle />
    </S.Base>
  );
};
