import React from "react";

import * as S from "./styles";
import { Trans } from "@lingui/macro";
import { useWizard } from "components/Wizard";
import { MainLayout } from "components/MainLayout";

const labels = [
  <Trans>application.progress.information</Trans>,
  <Trans>application.progress.contacts</Trans>,
  // <Trans>application.progress.assets</Trans>,
  <Trans>application.progress.confirmation</Trans>
];

export type FlowLayoutProps = {
  children: {
    heading: React.ReactNode;
    main: React.ReactNode;
    buttons: React.ReactNode;
  };
};

export const Layout: React.FC<FlowLayoutProps> = ({ children }) => {
  const { step, steps } = useWizard();
  const { heading, main, buttons } = children;

  return (
    <MainLayout>
      <S.Heading>{heading}</S.Heading>
      <S.Progress>
        {Array.from({ length: steps }, (_, i) => (
          <S.Step key={i} active={step === i + 1} past={step > i + 1}>
            {labels[i]}
          </S.Step>
        ))}
      </S.Progress>
      <S.Base>{main}</S.Base>
      <S.Buttons>{buttons}</S.Buttons>
    </MainLayout>
  );
};
