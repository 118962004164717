import Cookies from "js-cookie";
import React from "react";
import { useLocation } from "react-router-dom";

import { useCognito } from "./useCognito";

export function useProtected() {
  const cognito = useCognito();
  const location = useLocation();

  React.useEffect(() => {
    if (!cognito.isLoggedIn) {
      Cookies.set("auth_referrer", location.pathname);
      return cognito.login();
    }
  }, [cognito, location.pathname]);

  return cognito.isLoggedIn;
}
