import { Catalogs, I18n } from "@lingui/core";
import {
  I18nProvider as LinguiProvider,
  withI18n,
  withI18nProps
} from "@lingui/react";
import React from "react";
import { v4 as uuid } from "uuid";

import { Locale } from "./types";

export type I18nContextValue = { uuid: string; i18n: I18n };

export const I18nContext = React.createContext<I18nContextValue>(null!);

export type I18nProviderProps = {
  locale: Locale;
};

export type I18nProviderState = {
  catalogs: Catalogs;
};

type LinkuiConsumerProps = withI18nProps & {
  children: (i18n: I18n) => React.ReactElement;
};

const LinguiConsumer = withI18n()(({ i18n, children }: LinkuiConsumerProps) => {
  return children(i18n);
});

export class I18nProvider extends React.Component<
  I18nProviderProps,
  I18nProviderState
> {
  state: I18nProviderState = {
    catalogs: {}
  };

  async loadCatalog(locale: Locale) {
    const catalog = await import(
      `@lingui/loader!locales/${locale}/messages.po`
    );

    this.setState(state => ({
      catalogs: {
        ...state.catalogs,
        [locale]: catalog
      }
    }));
  }

  componentDidMount() {
    this.loadCatalog(this.props.locale);
  }

  shouldComponentUpdate(
    nextProps: I18nProviderProps,
    nextState: I18nProviderState
  ) {
    const { locale } = nextProps;
    const { catalogs } = nextState;

    if (locale !== this.props.locale && !catalogs[locale]) {
      this.loadCatalog(locale);
      return false;
    }

    return true;
  }

  render() {
    const { locale, children } = this.props;
    const { catalogs } = this.state;

    return (
      <LinguiProvider language={locale} catalogs={catalogs}>
        <LinguiConsumer>
          {i18n => (
            // Pass an uuid to ensure I18nContext rerenders everytime i18n changes
            <I18nContext.Provider value={{ uuid: uuid(), i18n }}>
              {catalogs[locale] && children}
            </I18nContext.Provider>
          )}
        </LinguiConsumer>
      </LinguiProvider>
    );
  }
}

export function useI18n() {
  const { i18n } = React.useContext(I18nContext);

  if (!i18n) throw new Error("useI18n must be used inside of I18nProvider");

  return i18n;
}
