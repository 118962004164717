import React from "react";

import { Cognito } from "./Cognito";
import { CognitoContext } from "./CognitoContext";

export type CognitoProviderProps = {
  cognito: Cognito;
};

export type CognitoProviderState = {
  isLoggedIn: boolean;
};

export const CognitoProvider: React.FC<CognitoProviderProps> = ({
  cognito,
  children
}) => {
  const [isLoggedIn, setIsLoggedIn] = React.useState(false);

  React.useEffect(() => {
    const handleSuccess = () => {
      setIsLoggedIn(true);
    };

    const handleError = () => {
      cognito.logout();
      window.location.reload();
    }

    cognito.events.addListener("success", handleSuccess);
    cognito.events.addListener("error", handleError);

    return () => {
      cognito.events.removeListener("success", handleSuccess);
      cognito.events.removeListener("error", handleError);
    };
  });

  return (
    <CognitoContext.Provider value={{ cognito, isLoggedIn }}>
      {children}
    </CognitoContext.Provider>
  );
};
