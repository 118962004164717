import styled from "styled-components";
import { ReactComponent as UnstyledCheckmarklIcon } from "assets/icons/circular-outline-checkmark.svg";
import { palette } from "@e-boks/react-dls";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 46rem;
  text-align: center;
`;

export const CheckmarklIcon = styled(UnstyledCheckmarklIcon)`
  width: 8.25rem;
  height: 8.25rem;
  margin-top: 4rem;
  margin-bottom: 2rem;
  fill: ${palette.blue[500].string()};
`;

export const Heading = styled.div`
  font-size: 2.5rem;
  margin-bottom: 2rem;
  font-weight: 300;
`;

export const Body = styled.div``;
