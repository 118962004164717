import React from "react";
import { useField } from "formik";
import { BaseField, BaseFieldProps } from "../Base";
import { TextareaAutosizeProps } from "react-textarea-autosize";
import * as S from "./styles";

export type TextareaProps = Omit<BaseFieldProps, "children"> &
  TextareaAutosizeProps;

export const Textarea: React.FC<TextareaProps> = ({ name, ...props }) => {
  const [field, meta] = useField(name);
  const error = meta.error && meta.touched ? meta.error : undefined;

  return (
    <BaseField name={name} error={error} {...props}>
      <S.Textarea {...field} />
    </BaseField>
  );
};
