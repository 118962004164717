import "focus-visible";
import "react-app-polyfill/stable";

import { ApolloProvider } from "@apollo/react-hooks";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { createApolloClient } from "services/apollo";
import { CognitoProvider, Cognito } from "@e-boks/react-cognito";

import { App } from "./App";

const cognito = new Cognito({
  domain: process.env.REACT_APP_COGNITO_DOMAIN || "",
  clientId: process.env.REACT_APP_ONBOARDING_COGNITO_CLIENT_ID || "",
  userPoolId: process.env.REACT_APP_COGNITO_POOL_ID || "",
  redirectUri: `${window.location.origin}/__auth/callback`,
  logoutUri: process.env.REACT_APP_LOGOUT_URI || "http://localhost:3000/logout",
  scope: ["openid", "profile"]
});

const apolloClient = createApolloClient(cognito);

ReactDOM.render(
  <CognitoProvider cognito={cognito}>
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApolloProvider>
  </CognitoProvider>,
  document.getElementById("root")
);
