import styled from "styled-components";
import { palette } from "../../../utils/palette";
import { BaseFieldProps } from ".";

export const Container = styled.div<Pick<BaseFieldProps, "block">>`
  display: flex;
  flex-direction: column;
  max-width: ${p => (p.block ? "100%" : "16rem")};
`;

export const Label = styled.label`
  line-height: 1;
  font-size: 0.875rem;
  margin-bottom: 0.5rem;
  color: ${palette.shade[700].string()};
`;

export const Field = styled.div`
  display: flex;
  flex-direction: column;

  .input,
  input,
  select,
  textarea {
    border-radius: 0.25rem;
    border: 0.0625rem solid ${palette.shade[500].string()};
    background: ${palette.shade[100].string()};
    min-height: 2.875rem;
    padding: 0 1.25rem;
    font-size: inherit;
    font-family: inherit;
    box-sizing: border-box;
    ::placeholder {
      color: ${palette.shade[500].string()};
    }
  }
`;

export const Error = styled.div`
  line-height: 1.5;
  font-size: 0.875rem;
  margin-top: 0.25rem;
  color: ${palette.red[500].string()};
`;
