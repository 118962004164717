import styled from "styled-components";
import { palette } from "@e-boks/react-dls";

export const Info = styled.div`
  border-top: 1px solid ${palette.shade[500].string()};
  padding-top: 2rem;

  @media (min-width: 60rem) {
    padding: 2rem 2rem 2rem 0;
  }
`;

export const Title = styled.div`
  line-height: 1;
  font-size: 1.25rem;
  color: ${palette.shade[900].string()};

  @media (min-width: 60rem) {
    align-self: center;
    font-size: 1.25rem;
  }
`;

export const Description = styled.div`
  font-size: 0.875rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  color: ${palette.shade[700].string()};

  @media (min-width: 60rem) {
    margin-bottom: 0;
  }
`;

export const Content = styled.div`
  padding-bottom: 2rem;

  @media (min-width: 60rem) {
    padding: 2rem 0 2rem 2rem;
    border-top: 1px solid ${palette.shade[500].string()};
  }
`;
