import React from "react";
import { Route, RouteProps } from "react-router-dom";

import { matchGroups } from "./matchGroups";
import { useCognito } from "./useCognito";
import { useProtected } from "./useProtected";

export type ProtectedRouteProps = {
  groups?: string | string[];
  matchedGroups?: boolean;
} & RouteProps;

export function ProtectedRoute<
  T extends ProtectedRouteProps = ProtectedRouteProps
>({ groups, matchedGroups, ...props }: T) {
  const cognito = useCognito();

  if (!useProtected()) return null;

  // If matchedGroups is defined, and false.
  if (typeof matchedGroups !== "undefined" && !matchedGroups) return null;

  if (groups && !matchGroups(cognito.user, groups || [])) return null;

  return <Route {...(props as RouteProps)} />;
}
