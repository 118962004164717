import { Trans } from "@lingui/macro";
import { MainLayout } from "components/MainLayout";
import React from "react";

import * as S from "./styles";

export const AwaitingApprovalPage: React.FC = () => {
  return (
    <MainLayout>
      <S.Container>
        <S.CheckmarklIcon />
        <S.Heading>
          <Trans>approval.heading</Trans>
        </S.Heading>
        <S.Body>
          <Trans>approval.body</Trans>
        </S.Body>
      </S.Container>
    </MainLayout>
  );
};
