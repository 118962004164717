import { Country, Locale } from "types";

export const countryToLocaleMap: { [T in Country]: Locale } = {
  US: "en-US",
  DK: "da-DK",
  SE: "sv-SE",
  NO: "nb-NO",
  IE: "ga-IE"
};

export const localeToCountryMap: { [T in Locale]: Country } = {
  "en-US": "US",
  "da-DK": "DK",
  "sv-SE": "SE",
  "nb-NO": "NO",
  "nn-NO": "NO",
  "ga-IE": "IE"
};

export const countryToLocale = (country: Country) => {
  return countryToLocaleMap[country];
};

export const localeToCountry = (locale: Locale) => {
  return localeToCountryMap[locale];
};

export const isValidCountry = (country: string) => {
  return countryToLocaleMap.hasOwnProperty(country);
};

export const isValidLocale = (locale: string) => {
  return localeToCountryMap.hasOwnProperty(locale);
};
