import { Locale } from "@e-boks/react-i18n";
import { CleaveOptions } from "cleave.js/options";

type InputFormat = {
  cleave?: CleaveOptions;
  validation?: any;
};

type InputFormats = { [key: string]: InputFormat };

const formats: { [T in Locale]?: InputFormats } = {
  "da-DK": {
    organisationId: {
      validation: { min: 8 },
      cleave: { numericOnly: true, blocks: [8] }
    },
    postalCode: {
      validation: { min: 4 },
      cleave: { numericOnly: true, blocks: [4] }
    },
    phone: {
      validation: { min: 8 },
      cleave: { numericOnly: true, blocks: [8] }
    }
  },
  "sv-SE": {
    organisationId: {
      validation: { min: 11 },
      cleave: { numericOnly: true, blocks: [6, 4], delimiter: "-" }
    },
    postalCode: {
      validation: { min: 6 },
      cleave: { numericOnly: true, blocks: [3, 2], delimiter: " " }
    },
    phone: {
      validation: { min: 8 },
      cleave: { numericOnly: true, blocks: [10] }
    }
  },
  "nb-NO": {
    organisationId: {
      validation: { min: 9 },
      cleave: { numericOnly: true, blocks: [9] }
    },
    postalCode: {
      validation: { min: 4 },
      cleave: { numericOnly: true, blocks: [4] }
    },
    phone: {
      validation: { min: 8 },
      cleave: { numericOnly: true, blocks: [8] }
    }
  }
};

export function inputFormat(language: string): InputFormats {
  if (!(language in formats))
    throw new Error(
      `Format for locale '${language}', has not been implemented.`
    );

  return formats[language];
}
