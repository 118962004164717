import styled from "styled-components";
import { palette } from "@e-boks/react-dls";
import { ReactComponent as UnstyledOpenIcon } from "assets/icons/open.svg";

export const Section = styled.div`
  grid-column: span 2;
  padding: 2rem 0;
  border-top: 1px solid ${palette.shade[500].string()};
`;

export const Link = styled.a`
  font-size: 1.25rem;
  text-decoration: underline;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
`;

export const OpenIcon = styled(UnstyledOpenIcon)`
  width: 1.125rem;
  height: 1.125rem;
  display: flex;
  fill: currentColor;
  margin-left: 1rem;
`;

export const Description = styled.div`
  font-size: 0.875rem;
  color: ${palette.shade[700].string()};
  margin-bottom: 1rem;

  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
`;
