import styled, { css } from "styled-components";

export const Container = styled.div<{ fullWidth: boolean }>`
  padding: 3rem 1rem;

  @media (min-width: 60rem) {
    margin: 0 auto;
    padding: 2.5rem 2rem;
    box-sizing: content-box;

    ${({ fullWidth }) =>
      !fullWidth &&
      css`
        max-width: 60rem;
      `}
  }
`;
