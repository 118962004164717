import React from "react";
import { MainLayout } from "components/MainLayout";
import { Trans } from "@lingui/macro";

export const UnauthorizedPage: React.FC = () => {
  return (
    <MainLayout>
      <Trans>pages.unauthorized.heading</Trans>
    </MainLayout>
  );
};
