import { palette } from "@e-boks/react-dls";
import styled, { css } from "styled-components";

export const Base = styled.div`
  display: grid;

  @media (min-width: 60rem) {
    grid-template-columns: 2fr 3fr;
  }
`;

export const Heading = styled.h1`
  font-size: 2.5rem;
  font-weight: 300;
  line-height: 1;
  margin: 0 0 3rem 0;
  color: ${palette.shade[900].string()};
`;

export const Progress = styled.div`
  display: flex;
  justify-content: space-between;
  overflow: hidden;
  margin-bottom: 3rem;
`;

export const Step = styled.div<{ past: boolean; active: boolean }>`
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;

  ${({ past, active }) => css`
    font-weight: ${active ? 700 : 400};
    color: ${past || active
      ? palette.blue[500].string()
      : palette.shade[500].string()};

    :before {
      content: "";
      display: block;
      width: 0.5625rem;
      height: 0.5625rem;
      border-radius: 5000rem;
      background: ${past
        ? palette.blue[500].string()
        : palette.blue[300].string()};
      border: 0.1875rem solid
        ${past || active
          ? palette.blue[500].string()
          : palette.shade[500].string()};
      box-shadow: 0 0 0 5px ${palette.blue[300].string()};
      margin-bottom: 0.5rem;
      z-index: 1;
    }

    :not(:last-child):after {
      content: "";
      top: 0.375rem;
      display: block;
      width: 100vh;
      height: 2px;
      background: ${past
        ? palette.blue[500].string()
        : palette.shade[500].string()};
      position: absolute;
      left: 50%;
    }

    :last-child:after {
      content: "";
      right: 0;
      display: block;
      width: 50%;
      height: 1rem;
      background: ${palette.blue[300].string()};
      position: absolute;
    }
  `};
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 2rem;

  > * {
    min-width: 10rem;
  }

  > *:not(:last-child) {
    margin-right: 2rem;
  }
`;
