import { Cognito } from "@e-boks/react-cognito";
import { InMemoryCache } from "apollo-cache-inmemory";
import ApolloClient from "apollo-client";
import { ApolloLink } from "apollo-link";
import { setContext } from "apollo-link-context";
import { RestLink } from "apollo-link-rest";

const createAuthLink = (cognito: Cognito) =>
  setContext(async (_, { headers = {} }) => {
    if (cognito.isLoggedIn) {
      headers.Authorization = `Bearer ${cognito.idToken}`;
    }

    return {
      headers
    };
  });

export const createApolloClient = (cognito: Cognito) => {
  return new ApolloClient({
    link: ApolloLink.from([
      createAuthLink(cognito),
      new RestLink({
        uri: process.env.REACT_APP_SENDER_API_URL!
      })
    ]),
    cache: new InMemoryCache()
  });
};
