import { filterProps } from "@e-boks/react-utils";
import { Link } from "react-router-dom";
import styled, { css } from "styled-components";
import { Required } from "utility-types";

import { ButtonProps } from ".";
import { palette } from "../../utils/palette";
import SpinnerUnstyled from "./spinner.svg";

type Props = Required<ButtonProps>;

const SpanFiltered = filterProps("span", "loading");
const SpinnerFiltered = filterProps(SpinnerUnstyled, "loading");
const ButtonFiltered = filterProps(
  "button",
  "nude",
  "color",
  "outline",
  "rounded",
  "loading"
);

export const LinkFiltered = filterProps(
  Link,
  "nude",
  "color",
  "outline",
  "rounded",
  "loading"
);

export const Base = styled(ButtonFiltered)<Props>`
  height: 2.875rem;
  padding: 0 1rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-family: "Open Sans", sans-serif;
  text-decoration: none;
  position: relative;
  user-select: none;
  cursor: pointer;
  outline: 0;
  border: 0;

  :disabled {
    cursor: not-allowed;
  }

  ${({ nude, outline, color, rounded }) => css`
    ${nude
      ? nudeColors[color]
      : outline
      ? outlineColors[color]
      : baseColors[color]};

    ${outline &&
      css`
        background-color: transparent;
      `}

    ${rounded &&
      css`
        border-radius: 99999px;
      `}
  `}
`;

const baseColors: { [T in Props["color"]]: ReturnType<typeof css> } = {
  red: css`
    color: ${palette.shade[100].string()};
    background-color: ${palette.red[500].string()};
    :hover {
      color: ${palette.shade[100].string()};
      background-color: ${palette.red[700].string()};
    }
    :disabled {
      background-color: ${palette.red[500].desaturate(0.5).string()};
    }
  `,
  blue: css`
    color: ${palette.shade[100].string()};
    background-color: ${palette.blue[500].string()};
    :hover {
      color: ${palette.shade[100].string()};
      background-color: ${palette.blue[700].string()};
    }
    :disabled {
      background-color: ${palette.blue[500].desaturate(0.5).string()};
    }
  `,
  white: css`
    color: ${palette.shade[700].string()};
    background-color: ${palette.shade[500].string()};
    :hover {
      color: ${palette.shade[700].string()};
      background-color: ${palette.shade[700].string()};
    }
    :disabled {
      background-color: ${palette.shade[500].desaturate(0.5).string()};
    }
  `
};

const nudeColors: { [T in Props["color"]]: ReturnType<typeof css> } = {
  red: css`
    color: ${palette.red[500].string()};
    border-color: ${palette.red[500].string()};
    background-color: transparent;
    :hover {
      color: ${palette.red[700].string()};
      border-color: ${palette.red[700].string()};
    }
  `,
  blue: css`
    color: ${palette.blue[500].string()};
    border-color: ${palette.blue[500].string()};
    background-color: transparent;
    :hover {
      color: ${palette.blue[700].string()};
      border-color: ${palette.blue[700].string()};
    }
  `,
  white: css`
    color: ${palette.shade[700].string()};
    border-color: ${palette.shade[500].string()};
    background-color: transparent;
    :hover {
      color: ${palette.shade[900].string()};
      border-color: ${palette.shade[700].string()};
    }
  `
};

const outlineColors: { [T in Props["color"]]: ReturnType<typeof css> } = {
  red: css`
    color: ${palette.red[500].string()};
    border: 1px solid ${palette.red[500].string()};
    :hover {
      color: ${palette.red[700].string()};
      border-color: ${palette.red[700].string()};
    }
  `,
  blue: css`
    color: ${palette.blue[500].string()};
    border: 1px solid ${palette.blue[500].string()};
    :hover {
      color: ${palette.blue[700].string()};
      border-color: ${palette.blue[700].string()};
    }
  `,
  white: css`
    color: ${palette.shade[100].string()};
    border: 1px solid ${palette.shade[100].string()};
    :hover {
      color: ${palette.shade[500].string()};
      border-color: ${palette.shade[500].string()};
    }
  `
};

export const Spinner = styled(SpinnerFiltered)<{ loading: boolean }>`
  width: 1.5rem;
  height: 1.5rem;
  display: ${p => (p.loading ? "block" : "none")};
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
`;

export const Text = styled(SpanFiltered)<{ loading: boolean }>`
  color: ${p => (p.loading ? "transparent" : "inherit")};
`;
