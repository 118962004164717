import Cookies from "js-cookie";
import React from "react";
import { useHistory } from "react-router-dom";

import { useCognito } from "./useCognito";

export const Callback: React.FC = () => {
  const cognito = useCognito();
  const history = useHistory();
  const referrer = Cookies.get("auth_referrer");

  Cookies.remove("auth_referrer");

  React.useEffect(() => {
    cognito.callback().finally(() => {
      history.replace(referrer || "/");
    });
  }, [cognito, history, referrer]);

  return null;
};
