import { createGlobalStyle } from "styled-components";
import { palette } from "../../utils/palette";

export const GlobalStyle = createGlobalStyle`
  * {
    font: inherit;
    box-sizing: inherit;
  }
  
  html {
    height: 100%;
    box-sizing: border-box;
    font-size: 16px;
  }

  body {
    height: 100%;
    font: 1rem/1.5 'Open Sans', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    background-color: ${palette.blue[300].string()};
    margin: 0
  }

  a {
    color: ${palette.blue[500].string()};
    text-decoration: none;
    
    &:hover {
      color: ${palette.blue[700].string()};
    }
  }

  .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
  } 

  .js-focus-visible [data-focus-visible-added] {
    outline: none;
    box-shadow: 0 0 0 1px white, 0 0 2px 2px ${palette.blue[500]
      .lighten(1)
      .string()};
  }
`;
