import styled from "styled-components";
import { NavLink as UnstyledNavLink } from "react-router-dom";

import { palette } from "../../../../utils/palette";

export const PageLayoutNavigationLink = styled(UnstyledNavLink)`
  display: flex;
  align-items: center;
  min-height: 1.5rem;
  padding: 0.5rem 0;
  margin: 0.5rem 0;
  box-sizing: content-box;
  line-height: 1;
  color: ${palette.shade[900].string()};

  &.active {
    font-weight: 700;
  }
`;
