import { palette } from "../../../../../utils/palette";
import styled, { createGlobalStyle, css } from "styled-components";
import UnstyledSenderLogo from "./sender.svg";
import UnstyledExpressLogo from "./express.svg";
import UnstyledOnboardingLogo from "./onboarding.svg";

export const GlobalStyle = createGlobalStyle<{ hasChildren: boolean }>`
  body {
    padding-top: ${({ hasChildren }) => (hasChildren ? 8 : 4)}rem;

    @media (min-width: 60rem) {
      padding-top: 4rem;
    }
  }
`;

export const Base = styled.header`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;

  background: ${palette.shade[100].string()};

  @media (min-width: 60rem) {
    display: flex;
  }
`;

export const Brand = styled.div`
  height: 4rem;

  padding: 0 1rem;
  border-bottom: 0.0625rem solid ${palette.shade[500].string()};

  @media (min-width: 60rem) {
    width: 320px;
    padding: 0 2rem;
  }
`;

export const BrandInner = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  font-size: 1.5rem;
  position: relative;
`;

export const Hamburger = styled.button<{ isMenuActive: boolean }>`
  width: 2.5rem;
  height: 2.5rem;
  position: absolute;
  background-color: ${palette.blue[300].string()};
  border-radius: 16000px;
  right: 0;
  padding: 0;
  border: 0;

  @media (min-width: 60rem) {
    display: none;
  }

  span {
    width: 1.25rem;
    height: 0.0625rem;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    background: ${palette.red[500].string()};
    transition: top 0.2s 0.2s ease, bottom 0.2s 0.2s ease, width 0s 0.2s ease,
      transform 0.2s ease;

    :nth-child(1) {
      bottom: 0.625rem;
    }
    :nth-child(3) {
      top: 0.625rem;
    }

    ${({ isMenuActive }) =>
      isMenuActive &&
      css`
        transition: top 0.2s ease, bottom 0.2s ease, width 0s 0.2s ease,
          transform 0.2s 0.2s ease;

        :nth-child(1) {
          bottom: 0;
          transform: rotate(45deg);
        }
        :nth-child(2) {
          width: 0;
        }
        :nth-child(3) {
          top: 0;
          transform: rotate(-45deg);
        }
      `}
  }
`;

export const Toolbar = styled.div`
  height: 4rem;
  padding: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-bottom: 0.0625rem solid ${palette.shade[500].string()};

  @media (max-width: 60rem) {
    ${({ children }) =>
      !children &&
      css`
        display: none;
      `}
  }

  @media (min-width: 60rem) {
    flex-grow: 1;
    padding: 0 2rem;
  }
`;

const logoStyles = css`
  height: 1.5rem;
  display: block;
  margin-right: 0.5rem;

  @media (min-width: 60rem) {
    height: 2rem;
  }
`;

export const SenderLogo = styled(UnstyledSenderLogo)`
  ${logoStyles}
`;
export const ExpressLogo = styled(UnstyledExpressLogo)`
  ${logoStyles}
`;
export const OnboardingLogo = styled(UnstyledOnboardingLogo)`
  ${logoStyles}
`;
