import React from "react";
import * as S from "./styles";

export type ContentProps = {
  fullWidth: boolean;
};

export const Content: React.FC<ContentProps> = ({ fullWidth, children }) => {
  return <S.Container fullWidth={fullWidth}>{children}</S.Container>;
};
