import React from "react";
import { LinkProps } from "react-router-dom";

import * as S from "./styles";

export type TableProps = {} & React.HTMLProps<HTMLTableElement>;

export type TableRowProps = {
  to?: LinkProps["to"];
  hover?: boolean;
} & React.HTMLProps<HTMLTableRowElement>;

export type TableHeaderProps = {
  grow?: boolean;
  shrink?: boolean;
} & React.HTMLProps<HTMLTableHeaderCellElement>;

export type TableCellProps = {
  to?: LinkProps["to"];
  color?: string;
  border?: boolean;
} & React.HTMLProps<HTMLTableCellElement>;

export const Table: React.FC<TableProps> = ({ children, ...props }) => (
  <S.Container>
    <S.Table {...(props as any)}>{children}</S.Table>
  </S.Container>
);

export const TableRow: React.FC<TableRowProps> = ({
  to,
  hover = false,
  children,
  ...props
}) => {
  return (
    <S.Row hover={hover} {...(props as any)}>
      {React.Children.map(children as React.ReactElement[], child =>
        React.cloneElement(child, { to, ...child!.props })
      )}
    </S.Row>
  );
};

export const TableHeader: React.FC<TableHeaderProps> = props => (
  <S.Header {...(props as any)} />
);

export const TableCell: React.FC<TableCellProps> = ({
  to,
  children,
  ...props
}) => {
  if (to)
    return (
      <S.Cell to={to} {...(props as any)}>
        <S.Link to={to}>{children}</S.Link>
      </S.Cell>
    );

  return <S.Cell {...(props as any)}>{children}</S.Cell>;
};
