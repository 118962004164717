import { useCognito } from "@e-boks/react-cognito";
import React from "react";

export const LogoutPage: React.FC = () => {
  const cognito = useCognito();

  React.useEffect(() => {
    cognito.logout();
  });

  return null;
};
