import { palette } from "@e-boks/react-dls";
import { ReactComponent as UnstyledLogoutIcon } from "assets/icons/logout-outline.svg";
import styled, { css } from "styled-components";

const iconStyles = css`
  width: 1.5rem;
  height: 1.5rem;
  margin-right: 1rem;
  fill: ${palette.red[500].string()};
`;

export const LogoutIcon = styled(UnstyledLogoutIcon)`
  ${iconStyles}
`;
