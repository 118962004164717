import { CognitoIdToken } from "amazon-cognito-auth-js";

export class User {
  email: string;
  locale: string;
  groups: string[];

  constructor(idToken: CognitoIdToken) {
    const data: any = idToken.decodePayload();

    this.email = data.email || null;
    this.locale = data.locale || null;
    this.groups = data["cognito:groups"] || [];
  }
}
