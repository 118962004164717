import gql from "graphql-tag";
import { useQuery, useMutation } from "@apollo/react-hooks";

const FRAGMENT_APPLICATION = gql`
  fragment ApplicationProps on Application {
    id
    name
    state
    information
    organisationId
    legal @rest(type: "ApplicationLegal", path: "/me/application/legal") {
      termsOfService @type(name: "ApplicationLegalTermsOfService") {
        id
        url
      }
      dataProcessing @type(name: "ApplicationLegalDataProcessing") {
        id
        url
      }
    }
    address @type(name: "ApplicationAddress") {
      street
      zipCode
      city
      country
    }
    generalContact @type(name: "ApplicationContact") {
      firstName
      lastName
      title
      email
      phone
    }
    economyContact @type(name: "ApplicationContact") {
      firstName
      lastName
      title
      email
      phone
    }
  }
`;

export const GET_APPLICATION = gql`
  query GetApplication {
    application @rest(type: "Application", path: "/me/application") {
      ...ApplicationProps
    }
  }

  ${FRAGMENT_APPLICATION}
`;

export const GET_APPLICATION_LEGAL = gql`
  query GetApplicationLegal {
    application @rest(type: "Application", path: "/me/application/legal") {
      termsOfService
    }
  }
`;

export const UPDATE_APPLICATION = gql`
  mutation UpdateApplication {
    updateApplication(input: $input)
      @rest(type: "Application", method: "PUT", path: "/me/application") {
      ...ApplicationProps
    }
  }

  ${FRAGMENT_APPLICATION}
`;

export const SUBMIT_APPLICATION = gql`
  mutation SubmitApplication {
    submitApplication(input: $input)
      @rest(
        type: "Application"
        method: "PUT"
        path: "/me/application/submit"
      ) {
      ...ApplicationProps
    }
  }

  ${FRAGMENT_APPLICATION}
`;

export const useGetApplication = () => useQuery(GET_APPLICATION);
export const useGetApplicationLegal = () => useQuery(GET_APPLICATION_LEGAL);
export const useUpdateApplication = () => useMutation(UPDATE_APPLICATION);
export const useSubmitApplication = () => useMutation(SUBMIT_APPLICATION);
