import React from "react";
import marked from "marked";

export type MarkedProps = {
  children: string;
};

export const Marked: React.FC<MarkedProps> = ({ children, ...props }) => {
  return (
    <div
      {...props}
      dangerouslySetInnerHTML={{
        __html: marked(children, {
          breaks: true
        })
      }}
    />
  );
};
