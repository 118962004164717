import { Button, Checkbox, Marked } from "@e-boks/react-dls";
import { t, Trans } from "@lingui/macro";
import { useWizard } from "components/Wizard";
import { Form, Formik, FormikConfig } from "formik";
import {
  GET_APPLICATION,
  useGetApplication,
  useSubmitApplication
} from "hooks/useApplication";
import React from "react";
import { useHistory } from "react-router-dom";
import { useI18n } from "@e-boks/react-i18n";
import * as yup from "yup";

import { Layout } from "../../components/Layout";
import * as S from "./styles";

export type SubmitPageValues = {
  termsOfService: boolean;
  dataProcessing: boolean;
  marketingMaterial: boolean;
};

export const SubmitPage: React.FC = () => {
  const i18n = useI18n();
  const wizard = useWizard();
  const history = useHistory();
  const { loading, error, data } = useGetApplication();
  const [submitApplication] = useSubmitApplication();

  if (error) return <div>error</div>;
  if (loading) return <div>loading...</div>;

  const { application } = data;

  const form: FormikConfig<SubmitPageValues> = {
    initialValues: {
      termsOfService: false,
      dataProcessing: false,
      marketingMaterial: false
    },

    validationSchema: yup.object().shape({
      termsOfService: yup
        .boolean()
        .oneOf([true], i18n._(t`application.termsOfService.error.checked`)),
      dataProcessing: yup
        .boolean()
        .oneOf([true], i18n._(t`application.dataProcessing.error.checked`)),
      marketingMaterial: yup.boolean()
    }),

    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        await submitApplication({
          variables: {
            input: {
              ...application,
              legal: [
                application.legal.termsOfService.id,
                application.legal.dataProcessing.id
              ],
              marketingAccepted: values.marketingMaterial
            }
          },
          refetchQueries: [{ query: GET_APPLICATION }],
          awaitRefetchQueries: true
        });

        window.location.href = 'https://corporate.e-boks.com/da/losninger/e-boks-express/tak';
        return false;
      } catch (error) {
        setSubmitting(false);
      }
    }
  };

  return (
    <Formik {...form}>
      {({ isSubmitting }) => (
        <Form>
          <Layout>
            {{
              heading: <Trans>application.submit.heading</Trans>,
              main: (
                <>
                  <S.Section>
                    <S.Link
                      target="_blank"
                      href={application.legal.termsOfService.url}
                    >
                      <Trans>application.termsOfService.link</Trans>
                      <S.OpenIcon />
                    </S.Link>
                    <Checkbox
                      name="termsOfService"
                      label={<Trans>application.termsOfService.label</Trans>}
                    />
                  </S.Section>

                  <S.Section>
                    <S.Link
                      target="_blank"
                      href={application.legal.dataProcessing.url}
                    >
                      <Trans>application.dataProcessing.link</Trans>
                      <S.OpenIcon />
                    </S.Link>
                    <Checkbox
                      name="dataProcessing"
                      label={<Trans>application.dataProcessing.label</Trans>}
                    />
                  </S.Section>

                  <S.Section>
                    <S.Description>
                      <Marked>
                        {i18n._(t`application.marketingMaterial.description`)}
                      </Marked>
                    </S.Description>
                    <Checkbox
                      name="marketingMaterial"
                      label={<Trans>application.marketingMaterial.label</Trans>}
                    />
                  </S.Section>
                </>
              ),
              buttons: (
                <>
                  <Button rounded outline color="blue" onClick={wizard.prev}>
                    <Trans>application.submit.prev</Trans>
                  </Button>
                  <Button
                    rounded
                    type="submit"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    <Trans>application.submit.next</Trans>
                  </Button>
                </>
              )
            }}
          </Layout>
        </Form>
      )}
    </Formik>
  );
};
