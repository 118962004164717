import React from "react";
import * as S from "./styles";
import { useField } from "formik";

export type CheckboxProps = { name: string; label?: React.ReactNode } & Omit<
  React.HTMLProps<HTMLInputElement>,
  "type" | "label"
>;

export const Checkbox: React.FC<CheckboxProps> = ({
  name,
  label,
  className,
  ...props
}) => {
  const [field, meta] = useField<boolean>(name);
  const error = meta.error && meta.touched ? meta.error : undefined;
  const checked = Boolean(meta.value);

  const input = {
    name,
    checked,
    id: name,
    type: "checkbox",
    value: String(meta.value),
    onChange: field.onChange,
    "aria-invalid": Boolean(error),
    "aria-errormessage": `${name}-errormessage`,
    "aria-describedby": `${name}-title ${name}-description`
  };

  return (
    <S.Base>
      <S.Field>
        <S.Input {...input} {...(props as any)} />
        <S.Box checked={checked}>
          <S.Checkmark checked={checked} />
        </S.Box>
        {label && <S.Label>{label}</S.Label>}
      </S.Field>
      {error && <S.Error id={`${name}-errormessage`}>{error}</S.Error>}
    </S.Base>
  );
};
