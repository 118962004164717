import { User } from "./User";
import { intersection } from "lodash";

export function matchGroups(user: User, acceptedGroups: string | string[]) {
  // Ensure groups is an array
  const groups = Array.isArray(acceptedGroups)
    ? acceptedGroups
    : [acceptedGroups];

  // Split groups in to groups to be included in and excluded from
  type GroupsMap = { included: string[]; excluded: string[] };
  const { included, excluded } = groups.reduce<GroupsMap>(
    (groups, group) => {
      if (group[0] === "!") groups.excluded.push(group.slice(1));
      else groups.included.push(group);
      return groups;
    },
    { included: [], excluded: [] }
  );

  // Ensure use has intersection groups with included and not with excluded
  if (included.length > 0 && !intersection(user.groups, included).length)
    return false;
  if (excluded.length > 0 && intersection(user.groups, excluded).length)
    return false;
  return true;
}
