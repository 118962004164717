import { palette } from "../../../../../utils/palette";
import styled, { createGlobalStyle, css } from "styled-components";

export const GlobalStyle = createGlobalStyle`
  body {
    @media (min-width: 60rem) {
      padding-left: 20rem;
    }
  }
`;

export const Base = styled.nav<{ isMenuActive: boolean }>`
  width: 100%;
  height: calc(100% - 4rem);
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1001;

  transform: translateX(-100%);
  transition: background-color 0.4s ease, transform 0s 0.4s;

  ${({ isMenuActive }) =>
    isMenuActive &&
    css`
      transform: translateX(0);
      background-color: rgba(0, 0, 0, 0.1);
      transition: background-color 0.4s ease, transform 0s;
    `}

  @media (min-width: 60rem) {
    width: 20rem;
    transform: translateX(0);
    background-color: transparent;
  }
`;

export const Inner = styled.div<{ isMenuActive: boolean }>`
  width: 80%;
  height: 100%;
  transform: translateX(-100%);
  background-color: ${palette.shade[100].string()};
  transition: transform 0.4s ease;
  padding: 3rem 1rem;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  @media (min-width: 60rem) {
    width: 100%;
    padding: 2.5rem 2rem;
    transform: translateX(0);
  }

  ${({ isMenuActive }) =>
    isMenuActive &&
    css`
      transform: translateX(0);
    `}
`;

// const iconStyles = css`
//   width: 1.5rem;
//   height: 1.5rem;
//   margin-right: 1rem;
//   fill: ${palette.red[500].string()};
// `;

// export const DashboardIcon = styled(UnstyledDashboardIcon)`
//   ${iconStyles}
// `;

// export const MessagesIcon = styled(UnstyledMessagesIcon)`
//   ${iconStyles}
// `;

// export const DraftsIcon = styled(UnstyledDraftsIcon)`
//   ${iconStyles}
// `;

// export const ProfileIcon = styled(UnstyledProfileIcon)`
//   ${iconStyles}
// `;

// export const LogoutIcon = styled(UnstyledLogoutIcon)`
//   ${iconStyles}
// `;
