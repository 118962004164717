import React from "react";

import { CognitoContext } from "./CognitoContext";

export function useCognito() {
  const context = React.useContext(CognitoContext);

  if (!context)
    throw new Error("useCognito must be used inside a CognitoProvider.");

  return context.cognito;
}
