import styled from "styled-components";

import { palette } from "../../../../utils/palette";

export const PageLayoutNavigationList = styled.ul`
  display: block;
  margin: 0;
  padding: 0;
`;

export const PageLayoutNavigationItem = styled.li`
  display: block;

  &:not(:first-child) {
    border-top: 1px solid ${palette.shade[500].string()};
  }
`;
