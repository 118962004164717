import React from "react";
import { omit } from "lodash";

export function filterProps<T>(
  element: React.FunctionComponent<T> | React.ComponentClass<T> | string,
  ...filter: string[]
): React.FC<T> {
  return props =>
    React.createElement<T>(element, omit(props as any, filter) as T);
}
