import React from "react";
import { createContext } from "@e-boks/react-utils";
import { useRouteMatch, useHistory, Route } from "react-router-dom";

export type WizardContextValue = {
  step: number;
  steps: number;
  next: () => void;
  prev: () => void;
};

export const [WizardContext, useWizard] = createContext<WizardContextValue>();

export type WizardProps = {
  steps: number;
};

export const Wizard: React.FC<WizardProps> = ({ steps, children }) => {
  const { url, path } = useRouteMatch();
  const history = useHistory();

  return (
    <Route
      path={`${path}/:step?`}
      render={({ match }) => {
        const step = Number(match.params.step);

        return (
          <WizardContext.Provider
            value={{
              step,
              steps,
              next: () => {
                history.push(`${url}/${step + 1}`);
              },
              prev: () => {
                history.push(`${url}/${step - 1}`);
              }
            }}
          >
            {children}
          </WizardContext.Provider>
        );
      }}
    />
  );
};
