import { Link as UnstyledLink, LinkProps } from "react-router-dom";
import styled, { css } from "styled-components";

import { TableRowProps, TableCellProps, TableHeaderProps } from ".";
import { palette } from "../../utils/palette";

const baseStyle = css`
  padding: 1rem;
  :first-child {
    padding-left: 0.5rem;
  }
  :last-child {
    padding-right: 0.5rem;
  }
`;

export const Container = styled.div`
  overflow: auto;
`;

export const Table = styled.table`
  min-width: 100%;
  border-collapse: collapse;
`;

export const Header = styled.th<TableHeaderProps>`
  width: ${p => (p.grow ? "99%" : p.shrink ? "1%" : "auto")};
  white-space: nowrap;
  text-align: left;
  font-weight: 500;
  border-bottom: 2px solid ${palette.shade[900].string()};
  ${baseStyle}
`;

export const Link = styled(UnstyledLink)<LinkProps>`
  width: 100%;
  height: 100%;
  display: block;
  color: inherit;
  box-sizing: border-box;
  ${baseStyle};
`;

export const Cell = styled.td<TableCellProps>`
  padding: 0;
  white-space: nowrap;
  border-bottom: ${({ border = true }) =>
    border ? `1px solid ${palette.shade[500].string()}` : "none"};

  ${({ to }) =>
    to
      ? css`
          ${Link} {
            padding: 1rem;
          }
          :first-child ${Link} {
            padding-left: 0.5rem;
          }
          :last-child ${Link} {
            padding-right: 0.5rem;
          }
        `
      : baseStyle}
`;

export const Row = styled.tr<TableRowProps>`
  ${({ hover }) =>
    hover &&
    css`
      :hover ${Cell} {
        background: ${palette.shade[100].string()};
      }
    `}
`;
