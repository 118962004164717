import React from "react";
import { Switch, SwitchProps } from "react-router-dom";

import { matchGroups } from "./matchGroups";
import { useCognito } from "./useCognito";
import { useProtected } from "./useProtected";

export type ProtectedSwitchProps = SwitchProps;

export function ProtectedSwitch<
  T extends ProtectedSwitchProps = ProtectedSwitchProps
>({ children, ...props }: T) {
  const cognito = useCognito();

  if (!useProtected()) return null;

  return (
    <Switch {...props}>
      {React.Children.map(children, child => {
        // If child isn't a React Element return the child
        if (!React.isValidElement(child)) return child;

        if ("groups" in child.props) {
          const match = matchGroups(cognito.user, child.props.groups);

          if (!match) return null;

          return React.cloneElement(child, {
            ...child.props,
            matchedGroups: match
          });
        }

        return React.cloneElement(child);
      })}
    </Switch>
  );
}
