import {
  Callback,
  ProtectedRoute,
  ProtectedSwitch,
} from "@e-boks/react-cognito";
import { GlobalStyle } from "@e-boks/react-dls";
import React from "react";
import { Helmet } from "react-helmet";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";

import { LogoutPage } from "./pages/Logout";
import { UnauthorizedPage } from "./pages/Unauthorized";
import { FlowPage } from "pages/Flow";
import { useGetApplication } from "hooks/useApplication";
import { I18nProvider, countryToLocale } from "@e-boks/react-i18n";

export const App: React.FC = () => {
  const { pathname } = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Helmet titleTemplate="%s | e-Boks Onboarding" />

      <Switch>
        <Route path="/__auth/callback" component={Callback} />
        <Route path="/unauthorized" component={UnauthorizedPage} />

        <ProtectedSwitch>
          <Route path="/logout" component={LogoutPage} />

          <ProtectedRoute groups="!e-boks" component={Routes} />

          <Redirect to="/unauthorized" />
        </ProtectedSwitch>
      </Switch>

      <GlobalStyle />
    </>
  );
};

const Routes: React.FC = () => {
  const { error, loading, data } = useGetApplication();

  if (loading) return null;

  if (error || !data || !data.application)
    return <Redirect to="/unauthorized" />;

  const locale = countryToLocale(data.application.address.country);

  return (
    <I18nProvider locale={locale}>
      <Switch>
        <Route path="/flow" component={FlowPage} />
        <Redirect to="/flow" />
      </Switch>
    </I18nProvider>
  );
};
