import styled from "styled-components";

export const AddressInputGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr 3fr;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;

  /* Make first element span all columns */
  *:first-child {
    grid-column: 1/-1;
  }
`;
