import { Button, Input } from "@e-boks/react-dls";
import { t, Trans } from "@lingui/macro";
import { useWizard } from "components/Wizard";
import { Form, Formik, FormikConfig } from "formik";
import {
  GET_APPLICATION,
  useGetApplication,
  useUpdateApplication
} from "hooks/useApplication";
import { Fieldset } from "pages/Flow/components/Fieldset";
import { Layout } from "pages/Flow/components/Layout";
import React from "react";
import { useI18n } from "@e-boks/react-i18n";
import * as yup from "yup";

import * as S from "./styles";
import { inputFormat } from "pages/Flow/formatting";

export type ContactsPageValues = {
  generalContact: {
    firstName: string;
    lastName: string;
    title: string;
    email: string;
    phone: string;
  };
  economyContact: {
    firstName: string;
    lastName: string;
    title: string;
    email: string;
    phone: string;
  };
};

export const ContactsPage: React.FC = () => {
  const i18n = useI18n();
  const wizard = useWizard();
  const { loading, error, data } = useGetApplication();
  const [updateApplication] = useUpdateApplication();

  if (error) return <div>error</div>;
  if (loading) return <div>loading...</div>;

  const { application } = data;

  const form: FormikConfig<ContactsPageValues> = {
    initialValues: {
      generalContact: {
        firstName: application.generalContact?.firstName || "",
        lastName: application.generalContact?.lastName || "",
        title: application.generalContact?.title || "",
        email: application.generalContact?.email || "",
        phone: application.generalContact?.phone || ""
      },
      economyContact: {
        firstName: application.economyContact?.firstName || "",
        lastName: application.economyContact?.lastName || "",
        title: application.economyContact?.title || "",
        email: application.economyContact?.email || "",
        phone: application.economyContact?.phone || ""
      }
    },

    validationSchema: yup.object().shape({
      generalContact: yup.object().shape({
        firstName: yup
          .string()
          .required(
            i18n._(t`application.generalContact.firstName.error.required`)
          ),
        lastName: yup
          .string()
          .required(
            i18n._(t`application.generalContact.lastName.error.required`)
          ),
        title: yup
          .string()
          .required(i18n._(t`application.generalContact.title.error.required`)),
        email: yup
          .string()
          .email(i18n._(t`application.generalContact.email.error.email`))
          .required(i18n._(t`application.generalContact.email.error.required`)),
        phone: yup
          .string()
          .min(8, i18n._(t`application.generalContact.phone.error.min`))
          .required(i18n._(t`application.generalContact.phone.error.required`))
      }),
      economyContact: yup.object().shape({
        firstName: yup
          .string()
          .required(
            i18n._(t`application.economyContact.firstName.error.required`)
          ),
        lastName: yup
          .string()
          .required(
            i18n._(t`application.economyContact.lastName.error.required`)
          ),
        title: yup
          .string()
          .required(i18n._(t`application.economyContact.title.error.required`)),
        email: yup
          .string()
          .email(i18n._(t`application.economyContact.email.error.email`))
          .required(i18n._(t`application.economyContact.email.error.required`)),
        phone: yup
          .string()
          .min(
            inputFormat(i18n.language).phone.validation.min,
            i18n._(t`application.economyContact.phone.error.min`)
          )
          .required(i18n._(t`application.economyContact.phone.error.required`))
      })
    }),

    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        await updateApplication({
          variables: { input: { ...application, ...values } },

          awaitRefetchQueries: true,
          refetchQueries: [
            {
              query: GET_APPLICATION
            }
          ]
        });
      } catch {
        setSubmitting(false);
      }

      wizard.next();
    }
  };

  return (
    <Formik {...form}>
      {({ isSubmitting }) => (
        <Form>
          <Layout>
            {{
              heading: <Trans>application.contacts.heading</Trans>,
              main: (
                <>
                  <Fieldset
                    name="generalContact.firstName"
                    title={<Trans>application.general.title</Trans>}
                    description={<Trans>application.general.description</Trans>}
                  >
                    <S.InputGroup>
                      <Input
                        name="generalContact.firstName"
                        label={i18n._(t`application.general.firstName.label`)}
                        placeholder={i18n._(
                          t`application.general.firstName.placeholder`
                        )}
                      />
                      <Input
                        name="generalContact.lastName"
                        label={i18n._(t`application.general.lastName.label`)}
                        placeholder={i18n._(
                          t`application.general.lastName.placeholder`
                        )}
                      />
                      <Input
                        block
                        name="generalContact.title"
                        label={i18n._(t`application.general.title.label`)}
                        placeholder={i18n._(
                          t`application.general.title.placeholder`
                        )}
                      />
                      <Input
                        block
                        name="generalContact.email"
                        label={i18n._(t`application.general.email.label`)}
                        placeholder={i18n._(
                          t`application.general.email.placeholder`
                        )}
                      />
                      <Input
                        block
                        name="generalContact.phone"
                        cleave={inputFormat(i18n.language).phone.cleave}
                        label={i18n._(t`application.general.phone.label`)}
                        placeholder={i18n._(
                          t`application.general.phone.placeholder`
                        )}
                      />
                    </S.InputGroup>
                  </Fieldset>

                  <Fieldset
                    name="economyContact.firstName"
                    title={<Trans>application.economy.title</Trans>}
                    description={<Trans>application.economy.description</Trans>}
                  >
                    <S.InputGroup>
                      <Input
                        name="economyContact.firstName"
                        label={i18n._(t`application.economy.firstName.label`)}
                        placeholder={i18n._(
                          t`application.economy.firstName.placeholder`
                        )}
                      />
                      <Input
                        name="economyContact.lastName"
                        label={i18n._(t`application.economy.lastName.label`)}
                        placeholder={i18n._(
                          t`application.economy.lastName.placeholder`
                        )}
                      />
                      <Input
                        block
                        name="economyContact.title"
                        label={i18n._(t`application.economy.title.label`)}
                        placeholder={i18n._(
                          t`application.economy.title.placeholder`
                        )}
                      />
                      <Input
                        block
                        name="economyContact.email"
                        label={i18n._(t`application.economy.email.label`)}
                        placeholder={i18n._(
                          t`application.economy.email.placeholder`
                        )}
                      />
                      <Input
                        block
                        name="economyContact.phone"
                        cleave={inputFormat(i18n.language).phone.cleave}
                        label={i18n._(t`application.economy.phone.label`)}
                        placeholder={i18n._(
                          t`application.economy.phone.placeholder`
                        )}
                      />
                    </S.InputGroup>
                  </Fieldset>
                </>
              ),
              buttons: (
                <>
                  <Button rounded outline color="blue" onClick={wizard.prev}>
                    <Trans>application.contacts.prev</Trans>
                  </Button>
                  <Button
                    rounded
                    type="submit"
                    loading={isSubmitting}
                    disabled={isSubmitting}
                  >
                    <Trans>application.contacts.next</Trans>
                  </Button>
                </>
              )
            }}
          </Layout>
        </Form>
      )}
    </Formik>
  );
};
