import React from "react";

import * as S from "./styles";
import { PageLayoutProps } from "../..";

export type BaseLayoutHeaderProps = {
  brand?: PageLayoutProps["brand"];
  isMenuActive?: boolean;
  onMenuToggleClick?: () => void;
};

export const Header: React.FC<BaseLayoutHeaderProps> = ({
  brand,
  children,
  isMenuActive = false,
  onMenuToggleClick = () => {}
}) => {
  return (
    <S.Base>
      <S.Brand>
        <S.BrandInner>
          <Logo brand={brand} />
          <S.Hamburger isMenuActive={isMenuActive} onClick={onMenuToggleClick}>
            <span />
            <span />
            <span />
          </S.Hamburger>
        </S.BrandInner>
      </S.Brand>
      <S.Toolbar>{children}</S.Toolbar>
      <S.GlobalStyle hasChildren={!!children} />
    </S.Base>
  );
};

type LogoProps = {
  brand: PageLayoutProps["brand"];
};
const Logo: React.FC<LogoProps> = ({ brand }) => {
  switch (brand) {
    case "sender":
      return <S.SenderLogo />;
    case "express":
      return <S.ExpressLogo />;
    case "onboarding":
      return <S.OnboardingLogo />;
    default:
      return null;
  }
};
