import Color from "color";

export const palette = {
  shade: {
    100: new Color("#ffffff"),
    500: new Color("#d8d8d8"),
    700: new Color("#707070"),
    900: new Color("#000000")
  },
  red: {
    500: new Color("#bb1d2c"),
    700: new Color("#84151f")
  },
  blue: {
    300: new Color("#f4f5f8"),
    500: new Color("#285870"),
    700: new Color("#233e4b")
  }
};
