import { palette } from "../../utils/palette";
import { hideVisually } from "polished";
import styled from "styled-components";

import UnstyledCheckmark from "./checkmark.svg";

export const Base = styled.label``;

export const Box = styled.div<{ checked: boolean }>`
  width: 1.5rem;
  height: 1.5rem;
  padding: 0.25rem;
  box-sizing: border-box;

  background: ${p =>
    p.checked ? palette.blue[500].string() : palette.shade[100].string()};
  border: 0.0625rem solid
    ${p =>
      p.checked ? palette.blue[500].string() : palette.shade[500].string()};
  border-radius: 0.25rem;
`;

export const Input = styled.input`
  ${hideVisually()};

  &[data-focus-visible-added] + ${Box} {
    box-shadow: 0 0 0 1px white,
      0 0 2px 2px ${palette.blue[500].lighten(1).string()};
  }
`;

export const Checkmark = styled(UnstyledCheckmark)<{ checked: boolean }>`
  display: ${p => (p.checked ? "block" : "none")};
  fill: ${palette.shade[100].string()};
`;

export const Field = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.div`
  color: ${palette.shade[700].string()};
  margin-left: 1rem;
`;

export const Error = styled.div`
  font-size: 0.875rem;
  line-height: 1.5;
  margin-top: 0.25rem;
  color: ${palette.red[500].string()};
`;
