import styled from "styled-components";
import { palette } from "../../utils/palette";

export const Base = styled.div`
  padding: 1.5rem 0;
  display: grid;

  @media (min-width: 60rem) {
    display: flex;
  }

  :not(:last-of-type) {
    border-bottom: 1px solid ${palette.shade[500].string()};
  }
`;

export const Meta = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: 60rem) {
    flex: 0 0 20rem;
    display: grid;
    grid-template-columns: auto 1fr;
    grid-template-rows: auto 1fr;
  }
`;

export const Title = styled.div`
  line-height: 1;
  font-size: 1.25rem;

  color: ${palette.shade[900].string()};

  @media (min-width: 60rem) {
    align-self: center;
    font-size: 1.25rem;
  }
`;

export const Description = styled.div`
  font-size: 0.875rem;
  color: ${palette.shade[700].string()};
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  grid-column: 2;

  @media (min-width: 60rem) {
    margin-bottom: 0;
    margin-right: 4rem;
  }
`;

export const Content = styled.div`
  flex-grow: 1;
`;
